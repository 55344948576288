<template>
  <thead>
    <tr>
      <template v-if="!changeLog">
        <th :class="[modifiedRangeData.isActive ? 'column-icon-88' : 'column-icon']"></th>
        <th class="width-46"><input type="checkbox" v-model="selectAll" :value="true" /></th>
      </template>
      <template v-if="changeLog">
        <th></th>
        <th @click="changeSorting(11)" class="sort"><p :class="getClassSorting(11)">Действие</p></th>
        <th @click="changeSorting(12)" class="sort"><p :class="getClassSorting(12)">Дата изменения</p></th>
        <th @click="changeSorting(13)" class="sort"><p :class="getClassSorting(13)">Пользователь</p></th>
      </template>
      <template v-for="column in tableHeader" :key="column.id">
        <th
          v-if="changeLog ? true : column.visible"
          @click="changeSorting(column.id)"
          :class="['sort', column.width ? `width-${column.width}` : '']"
        >
          <p :class="getClassSorting(column.id)" v-html="column.name"></p>
        </th>
      </template>
    </tr>
    <tr v-if="!modifiedRangeData.isActive || changeLog">
      <th></th>
      <th></th>
      <th v-if="changeLog"></th>
      <th v-if="changeLog"></th>
      <template v-for="column in tableHeader" :key="column.id">
        <th v-if="changeLog ? true : column.visible">
          <template v-if="column.id == tableIdColumns.tradingOperation">
            <SelectMultiComponent
              v-model="filter.tradingOperationId"
              :options="moveDirections"
              placeholder="Выберите"
              modifier="filter-small"
              classSelect="visible-clear"
            />
          </template>
          <template v-if="column.id == tableIdColumns.countries">
            <SelectMultiComponent
              v-model="filter.countryIds"
              :options="getCountriesMultiSelect"
              placeholder="Выберите"
              modifier="filter-small"
              classSelect="visible-clear"
            />
          </template>
          <template v-if="column.id == tableIdColumns.name">
            <ComponentInput
              placeholder="Название"
              mod="filter-small"
              v-model="filter.name"
              clearButton
              @onClear="filter.name = ''"
            />
          </template>
          <template v-if="column.id == tableIdColumns.tnvedGroup">
            <ComponentInput
              placeholder="Товар"
              mod="filter-small"
              v-model="filter.tnvedAlias"
              clearButton
              @onClear="filter.tnvedAlias = ''"
            />
          </template>
          <template v-if="column.id == tableIdColumns.tnvedCodes">
            <ComponentInput
              placeholder="Код ТН ВЭД ЕАЭС"
              mod="filter-small"
              v-model="filter.tnvedCodes"
              clearButton
              @onClear="filter.tnvedCodes = ''"
            />
          </template>
          <template v-if="column.id == tableIdColumns.industries">
            <SelectMultiComponent
              v-model="filter.industryId"
              :options="industries"
              placeholder="Выберите"
              modifier="filter-small"
              classSelect="visible-clear"
            />
          </template>
          <template v-if="column.id == tableIdColumns.periodDate">
            <div class="horizontally-date">
              <dateComponent v-model="filter.periodDateFrom" modClass="filter-small" :upperLimit="upperLimitDateFrom" />
              <dateComponent
                v-model="filter.periodDateTo"
                modClass="filter-small"
                :upperLimit="upperLimitTo"
                :lowerLimit="lowerLimitDateTo"
              />
            </div>
          </template>
          <template v-if="column.id == tableIdColumns.legalActs">
            <ComponentInput
              placeholder="Правовой акт"
              mod="filter-small"
              v-model="filter.legalActs"
              clearButton
              @onClear="filter.legalActs = ''"
            />
          </template>
          <template v-if="column.id == tableIdColumns.comment">
            <ComponentInput
              placeholder="Комментарий"
              mod="filter-small"
              v-model="filter.comment"
              clearButton
              @onClear="filter.comment = ''"
            />
          </template>
          <template v-if="column.id == tableIdColumns.countryCoverages">
            <SelectMultiComponent
              v-model="filter.countryCoverageId"
              :options="countryCoveragesList"
              placeholder="Выберите"
              modifier="filter-small"
              classSelect="visible-clear"
            />
          </template>
          <template v-if="column.id == tableIdColumns.isActive">
            <SelectMultiComponent
              v-model="filter.isActive"
              :options="getStatusOptions"
              placeholder="(Все)"
              modifier="filter-small"
              classSelect="visible-clear"
            />
          </template>
        </th>
      </template>
    </tr>
  </thead>
</template>

<script>
  import { debounce } from 'lodash';
  import moment from 'moment';

  import ComponentInput from '@/common/components/ComponentInput';
  import dateComponent from '@/common/components/dateComponent.vue';
  import SelectMultiComponent from '@/common/components/SelectMultiComponent.vue';
  import Constants from '@/common/constants';

  import duplicateMethods from '../../mixins/duplicateMethods';
  import { TABLE_ID_UNILATERAL } from '../../utils/constants';

  export default {
    name: 'HeaderTable',
    emits: ['changeSorting', 'changeFilter', 'changeSelectAll'],
    mixins: [duplicateMethods],
    components: {
      ComponentInput,
      dateComponent,
      SelectMultiComponent,
    },
    props: {
      modifiedRangeData: {
        type: Object,
        default: () => {
          return {
            isActive: false,
            showModal: false,
            filter: null,
            filterText: null,
          };
        },
      },
      tableHeader: {
        type: Array,
        default: () => [],
      },
      changeLog: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        sortColumn: 0,
        sortOrder: 0,
        filter: {
          tradingOperationId: '',
          countryIds: '',
          tnvedAlias: '',
          tnvedCodes: '',
          industries: '',
          periodDateFrom: '',
          periodDateTo: '',
          legalActs: '',
          comment: '',
          countryCoverageId: '',
          isActive: '',
        },
        selectAll: false,
        upperLimitTo: new Date(),
        tableIdColumns: TABLE_ID_UNILATERAL,
      };
    },
    computed: {
      upperLimitDateFrom() {
        if (this.filter.periodDateTo) {
          return moment(this.filter.periodDateTo, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
        }
        return new Date();
      },
      lowerLimitDateTo() {
        return moment(this.filter.periodDateFrom, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
      },
    },
    methods: {
      getClassSorting(id) {
        if (this.sortColumn == id) {
          if (this.sortOrder == 0) return this.sortClassName.ascending;
          else if (this.sortOrder == 1) return this.sortClassName.descending;
        }
        return this.sortClassName.default;
      },

      changeSorting(id) {
        if (this.sortColumn == id) this.sortOrder = this.sortOrder == 0 ? 1 : 0;
        else this.sortOrder = 0;
        this.sortColumn = id;

        this.$emit('changeSorting', {
          sortColumn: this.sortColumn,
          sortOrder: this.sortOrder,
        });
      },

      changeFilter: debounce(function () {
        this.$emit('changeFilter', {
          ...this.filter,
          countryIds: this.filter?.countryIds ? [this.filter.countryIds] : [],
        });
      }, 500),

      /**
       * @public
       * Вызывается в родительском компоненте через ref
       */
      clearFilter() {
        this.filter.tradingOperationId = '';
        this.filter.countryIds = '';
        this.filter.tnvedAlias = '';
        this.filter.tnvedCodes = '';
        this.filter.industries = '';
        this.filter.periodDateFrom = '';
        this.filter.periodDateTo = '';
        this.filter.legalActs = '';
        this.filter.comment = '';
        this.filter.countryCoverageId = '';
        this.filter.isActive = '';
      },
    },
    watch: {
      filter: {
        handler() {
          this.changeFilter();
        },
        deep: true,
      },

      selectAll(value) {
        this.$emit('changeSelectAll', value);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/table/table-base.scss';
  @import '../../styles/index.scss';
</style>
