<template>
  <div class="container-page pb-0">
    <div class="form__body">
      <label class="form-label">Дата изменения</label>
      <div class="form__body-inputBlock d-flex form__body-inputBlock--first">
        <div class="required d-flex align-items-center w-50 justify-content-around">
          <label class="form-label-no-margin">От</label>
          <div class="modal-date-container error-position-absolute">
            <dateComponent v-model="filter.modifiedFrom" :clearable="true" :upperLimit="upperLimitFrom" required />
          </div>
        </div>
        <div class="required d-flex align-items-center w-50 justify-content-around">
          <label class="form-label-no-margin">До</label>
          <div class="modal-date-container error-position-absolute">
            <dateComponent v-model="filter.modifiedTo" :clearable="true" :lowerLimit="lowerLimitTo" required />
          </div>
        </div>
      </div>
      <div class="form__body-inputBlock mb-3">
        <ComponentInput
          label="Товар"
          :select2Settings="select2SettingsTnvedGroupWithAjax"
          v-model="filter.tnvedGroupId"
          type="select2"
          placeholder="Выберите товар"
          @select2OnSelect="onTnvedGroupSelect"
        />
      </div>
      <div class="form__body-inputBlock mb-3">
        <ComponentInput
          label="Код ТН ВЭД ЕАЭС"
          :select2Settings="select2SettingsTnvedWithAjax"
          v-model="filter.tnvedCodes"
          type="select2"
          placeholder="Выберите Код ТН ВЭД ЕАЭС"
          @select2OnSelect="onTnvedSelect"
        />
      </div>
      <div class="form__body-inputBlock mb-3">
        <ComponentInput
          label="Страна"
          :select2Settings="select2Settings"
          :select2Options="countries"
          v-model="filter.countryId"
          type="select2"
          placeholder="Выберите страну"
          @select2OnSelect="onCountrySelect"
        />
      </div>
    </div>
    <div class="form-group col-md-auto">
      <ButtonStock class="mr-2" title="Показать" @click="filterData" />
      <ButtonStock btn-type="secondary" title="Закрыть" v-on:click="close" />
    </div>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';
  import moment from 'moment';

  import ComponentInput from '@/common/components/ComponentInput';
  import dateComponent from '@/common/components/dateComponent';
  import Constants from '@/common/constants';
  import runtimeConfig from '@/common/runtime-config';

  import ButtonStock from '@/components/buttons/ButtonStock';

  import CountryApi from '@/modules/nsi/api/country';

  export default {
    name: 'UnilateralNonTrFilterModifiedComponent',
    components: { ComponentInput, ButtonStock, dateComponent },
    emits: ['filter', 'close'],
    data() {
      return {
        filter: {
          modifiedFrom: null,
          modifiedTo: null,
          tnvedGroupId: null,
          tnvedCodes: null,
          countryId: null,
        },
        tnvedName: '',
        tnvedGroupName: '',
        countryName: '',
        select2Settings: Constants.select2Settings,
        select2SettingsTnvedGroupWithAjax: null,
        select2SettingsTnvedWithAjax: null,
      };
    },
    created() {
      this.select2SettingsTnvedGroupWithAjax = this.getTnvedAjaxSettings(6, (x) => ({
        id: x.id,
        text: x.code + ' - ' + x.name,
      }));
      this.select2SettingsTnvedWithAjax = this.getTnvedAjaxSettings(10, (x) => ({
        id: x.code,
        text: x.code + ' - ' + x.name,
      }));
      this.loadDictionaries();
    },
    computed: {
      upperLimitFrom() {
        if (this.filter.modifiedTo) {
          return moment(this.filter.modifiedTo, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
        }
        return new Date(2099, 0, 1);
      },
      lowerLimitTo() {
        if (this.filter.modifiedFrom) {
          return moment(this.filter.modifiedFrom, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
        }
        return '';
      },
    },
    methods: {
      loadDictionaries() {
        CountryApi.getList()
          .then((response) => {
            this.countries = response.data;
          })
          .catch((error) => {
            console.error(error.response.data);
          });
      },
      filterData() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }

        this.$emit('filter', this.filter, this.getFilterText());
      },
      close() {
        this.$emit('close');
      },
      getFilterText() {
        let result =
          'Период: ' +
          moment(this.filter.modifiedFrom).format('DD.MM.YYYY') +
          ' - ' +
          moment(this.filter.modifiedTo).format('DD.MM.YYYY') +
          (this.tnvedGroupName ? ', товар: ' + this.tnvedGroupName : '') +
          (this.tnvedName ? ', код: ' + this.tnvedName : '') +
          (this.countryName ? ', страна: ' + this.countryName : '');

        return result;
      },
      onTnvedGroupSelect(e) {
        this.tnvedGroupName = e.value.selected ? e.value.text : '';
      },
      onTnvedSelect(e) {
        this.tnvedName = e.value.selected ? e.value.text : '';
      },
      onCountrySelect(e) {
        this.countryName = e.value.selected ? e.value.text : '';
      },
      getTnvedAjaxSettings(codeLength, mapFunction) {
        return {
          language: Constants.langRu,
          allowClear: true,
          ajax: {
            delay: 500,
            url: runtimeConfig.apiBaseUrl + '/api/nsi/nsitnved/search',
            dataType: 'json',
            data: function (params) {
              var query = {
                pageNumber: 1,
                pageSize: 50,
                codeLength: codeLength,
                term: params.term,
              };
              return query;
            },
            processResults: function (responseData) {
              return {
                results: responseData.items.map(mapFunction),
              };
            },
            cache: true,
          },
        };
      },

      /**
       * @public
       * Вызывается в родительском компоненте через ref
       */
      reset() {
        this.filter.modifiedFrom = null;
        this.filter.modifiedTo = null;
        this.filter.tnvedId = null;
        this.filter.tnvedBeforeCode = null;
        this.filter.initiator = null;
        this.filter.decisionNumber = null;
        this.v$.$reset();
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        filter: {},
      };
    },
  };
</script>

<style lang="scss" scoped>
  .modal-date-container::v-deep {
    .v3dp__popout {
      right: unset;
      position: fixed !important;
      z-index: 11110 !important;
    }
  }
  .form-label,
  .form-label-no-margin {
    font-weight: 400 !important;
    font-size: 14px;
    color: #231f20;
    line-height: 160%;
  }
  .form-label-no-margin {
    margin-bottom: 0;
  }
  .form__body-inputBlock {
    margin-bottom: 10px !important;
    padding-bottom: 10px !important;

    &--first {
      padding-bottom: 20px !important;
    }
  }
</style>
